import { storyblokEditable } from '@storyblok/react';
import React from 'react';
import RichtextContent from './base/RichtextContent';
import { StoryblokLink } from '@/types/StoryblokLink';


type RichtextContentItem = {
  type: string;
  content?: RichtextContentItem[];
  marks?: any[];
  text?: string;
  attrs?: {
    body?: MenuLinkBlok[];
  };
  _uid?: string;
};

type MenuLinkBlok = {
  component: string;
  link_text: string;
  link: StoryblokLink;
  _editable: string;
  _uid: string;
};

type Richtext = {
  type: string;
  content: RichtextContentItem[];
};

type FeatureProps = {
  blok: {
    name: string;
    description: Richtext;
  },
  darkmode?: boolean;
};

/**
 * The Feature component that renders the name and description richtext.
 * @param blok The blok object containing name and description.
 */
const Feature = ({ blok, darkmode = false }: FeatureProps) => {
  return (
    <div className='w-full text-left p-2' {...storyblokEditable(blok)}>
      {blok.name && (
        <h3 className={darkmode ? 'pt-[9px] pb-[10px] text-[#ffffff] border-t border-b style-2-mobile tablet:style-2' : 'pt-[9px] pb-[10px] text-[#1d243d] border-t border-b style-2-mobile tablet:style-2'}>
          {blok.name}
        </h3>
      )}
      <div
        className={`style-1-mobile tablet:style-1 ${blok.name ? 'mt-2' : ''}`}
      >
        {!!blok.description.content?.length && (
          <RichtextContent content={blok.description.content} darkmode={darkmode} />
        )}
      </div>
    </div>
  );
};

export default React.memo(Feature);