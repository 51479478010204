import { useState } from 'react';
import type { Swiper } from 'swiper';
import type { AutoplayOptions } from 'swiper/types';

const MAX_SPEED = 100;
const MIN_SPEED = 800;
const ULTRA_SLOW_SPEED = 2000;
const CENTER_THRESHOLD = 0.3;
const SIDE_THRESHOLD = 0.7;

export const useSliderControls = (swiperRef: React.RefObject<Swiper>) => {
    const [isRightSide, setIsRightSide] = useState(false);
    const [speed, setSpeed] = useState(MIN_SPEED);
    const [cursorStyle, setCursorStyle] = useState<string>('auto');
    const [isFirstHover, setIsFirstHover] = useState(true);

    const updateSliderDirection = (isRight: boolean) => {
        if (swiperRef.current?.autoplay) {
            swiperRef.current.params.autoplay = {
                ...(swiperRef.current.params.autoplay as AutoplayOptions),
                reverseDirection: !isRight,
            };
            swiperRef.current.autoplay.stop();
            swiperRef.current.autoplay.start();
        }
    };

    const updateSliderSpeed = (newSpeed: number) => {
        if (swiperRef.current?.autoplay) {
            swiperRef.current.params.autoplay = {
                ...(swiperRef.current.params.autoplay as AutoplayOptions),
                delay: 1,
            };
            swiperRef.current.params.speed = Math.max(50, newSpeed);
            swiperRef.current.params.freeMode = {
                enabled: true,
                momentum: false,
                momentumRatio: 1,
                momentumVelocityRatio: 1,
                sticky: false,
            };
        }
    };

    const resetSlider = () => {
        if (swiperRef.current?.autoplay) {
            swiperRef.current.params.autoplay = {
                ...(swiperRef.current.params.autoplay as AutoplayOptions),
                delay: MIN_SPEED,
                reverseDirection: false,
            };
            swiperRef.current.params.speed = 1000;
            swiperRef.current.params.freeMode = {
                enabled: true,
                momentum: true,
                momentumRatio: 0.8,
                momentumVelocityRatio: 0.8,
                sticky: false,
            };
            swiperRef.current.autoplay.stop();
            swiperRef.current.autoplay.start();
        }
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const width = rect.width;

        const centerX = width / 2;
        const distanceFromCenter = Math.abs(x - centerX) / centerX;
        const isRight = x > centerX;

        let newSpeed: number;
        if (distanceFromCenter < CENTER_THRESHOLD) {
            const centerFactor = 1 - (distanceFromCenter / CENTER_THRESHOLD);
            newSpeed = MIN_SPEED + (centerFactor * (ULTRA_SLOW_SPEED - MIN_SPEED));
        } else {
            const speedRange = MIN_SPEED - MAX_SPEED;
            const normalizedDistance = (distanceFromCenter - CENTER_THRESHOLD) / (1 - CENTER_THRESHOLD);
            newSpeed = MIN_SPEED - (normalizedDistance * speedRange);
        }

        if (distanceFromCenter > SIDE_THRESHOLD) {
            setCursorStyle(isRight ? 'e-resize' : 'w-resize');
        } else {
            setCursorStyle('auto');
        }

        if (isFirstHover) {
            setIsFirstHover(false);
            updateSliderDirection(isRight);
        }

        if (isRight !== isRightSide) {
            setIsRightSide(isRight);
            updateSliderDirection(isRight);
        }

        setSpeed(newSpeed);
        updateSliderSpeed(newSpeed);
    };

    const handleMouseLeave = () => {
        setSpeed(MIN_SPEED);
        setCursorStyle('auto');
        setIsFirstHover(true);
        resetSlider();
    };

    return {
        speed,
        cursorStyle,
        handleMouseMove,
        handleMouseLeave,
    };
};