import { useState } from 'react';
import CtaButton from '../../assets/cta-contact.svg';
import { VinylContact } from '../base/VinylContact';
import { IconButton } from '@mui/material';
import cross from '../../assets/cross.svg';

export const CtaContact = () => {
    const [isVisible, setVisible] = useState(false);

    const toggleVisibility = () => {
        setVisible(!isVisible);
    };

    return (
        <>
            <div className='relative flex justify-center items-center w-[120px] h-[120px] tablet:w-[200px] tablet:h-[200px] bg-transparent'>
                <p onClick={toggleVisibility} className='absolute z-50 cursor-pointer'>
                    <img
                        src={CtaButton}
                        alt='Get in touch'
                        className='w-[90px] tablet:w-[126px] desktop:w-[166px]'
                    />
                </p>
                <div className='animate-[blendCTACircle1_1.4s_linear_infinite] absolute w-[90px] h-[90px] tablet:w-[126px] tablet:h-[126px] desktop:w-[166px] desktop:h-[166px] rounded-full bg-flieder z-40'></div>
                <div className='animate-[blendCTACircle2_1.4s_linear_infinite] absolute outline outline-flieder/75 w-[90px] h-[90px] tablet:w-[126px] tablet:h-[126px] desktop:w-[166px] desktop:h-[166px] bg-transparent rounded-full z-30'></div>
                <div className='animate-[blendCTACircle3_1.4s_linear_infinite] absolute outline outline-flieder/45 outline-offset-[14px] w-[90px] h-[90px] tablet:w-[126px] tablet:h-[126px] desktop:w-[166px] desktop:h-[166px] bg-transparent rounded-full z-20'></div>
                <div className='animate-[blendCTACircle4_1.4s_linear_infinite] absolute outline outline-flieder/20 outline-offset-[26px] w-[90px] h-[90px] tablet:w-[126px] tablet:h-[126px] desktop:w-[166px] desktop:h-[166px] rounded-full z-10'></div>
            </div>
            <div className={`${isVisible ? "block" : "hidden"} w-screen h-screen fixed top-10 left-0 bg-transparent overscroll-contain overflow-hidden z-[999]`}>
                <div onClick={toggleVisibility} className='absolute group flex flex-col justify-center items-center flex-wrap z-30 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer'>
                    <IconButton>
                        <img
                            src={cross}
                            alt='cross'
                            className='h-[22px] w-[22px] tablet:h-[46px] tablet:w-[46px] group-hover:scale-150 group-hover:opacity-45'
                        />
                    </IconButton>
                    <p className='text-white style-13  group-hover:opacity-0 group-hover:scale-0'>Close</p>
                </div>
                <div className='flex flex-nowrap flex-col phablet:flex-row absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                    <div>
                        <VinylContact
                            type='email'
                            topline="write us an"
                            headline='EMAIL'
                            emailAddress='info@ae.ag'
                        />
                    </div>
                    <div className='mt-[-100px] phablet:mt-0 phablet:ml-[-120px] tablet:ml-[-180px] desktop:ml-[-300px] z-20'>
                        <VinylContact
                            type='phone'
                            topline='talk to us on the'
                            headline='phone'
                            subline='+49 6894 16 89 30 00'
                            phoneNumber='+49689416893000'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};


