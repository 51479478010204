import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

import { storyblokInit, apiPlugin } from '@storyblok/react';
import Page from './components/Page';
import Teaser from './components/Teaser';
import Feature from './components/Feature';
import Stage from './components/modules/Stage';

import HeaderMenu from './components/HeaderMenu';
import MenuLink from './components/MenuLink';
import Team from './components/modules/Team';
import Showcase from './components/modules/ShowCase';
import CopyCenter from './components/modules/CopyCenter';
import Split from './components/modules/Split';
import LookBook from './components/modules/LookBook';
import ProjectDetails from './components/ProjectDetails';
import WokingAtAE from './components/modules/WorkingAtAE';
import Benefits from './components/modules/Benefits';
import JobList from './components/modules/JobList';
import JobDetails from './components/modules/JobDetails';
import Form from './components/Form';
import FooterMenu from './components/FooterMenu';
import { Services } from './components/modules/Services';
import GenericRichText from './components/base/GenericRichText';
import GenericTitle from './components/base/GenericTitle';
import ErrorBoundary from './components/ErrorBoundary';

storyblokInit({
  accessToken: '4zh3ILfpd0uScORg1kM9jAtt', // Space -> Settings -> Access Token -> Preview
  use: [apiPlugin],
  components: {
    page: Page,
    teaser: Teaser,
    feature: Feature,
    header_menu: HeaderMenu,
    menu_link: MenuLink,
    footer_menu: FooterMenu,
    job_banner: CopyCenter,
    stage: Stage,
    team: Team,
    showcase: Showcase,
    split: Split,
    lookbook: LookBook,
    project_details: ProjectDetails,
    working_at_AE: WokingAtAE,
    benefits: Benefits,
    job_list: JobList,
    job_details: JobDetails,
    form: Form,
    services: Services,
    generic_rich_text: GenericRichText,
    generic_title: GenericTitle,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <React.StrictMode>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </React.StrictMode>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
