import React from 'react';

type GalleryImageProps = {
  fileName?: string;
  alt?: string;
  isTwoColumn?: boolean;
};

// sizes of picture srcset coming from https://medium.com/hceverything/applying-srcset-choosing-the-right-sizes-for-responsive-images-at-different-breakpoints-a0433450a4a3

const GalleryImage: React.FC<GalleryImageProps> = ({ fileName, alt, isTwoColumn }) => {
  return (
    <div className={isTwoColumn ? 'w-1/2 float-left h-auto overflow-hidden' : 'w-full h-auto overflow-hidden'}>
      <picture>
        <source srcSet={`${fileName}/m/640x0 1x,${fileName}/m/1280x0 2x`} media='(max-width: 640px)' />
        <source srcSet={`${fileName}/m/768x0 1x,${fileName}/m/1536x0 2x`} media='(max-width: 768px)' />
        <source srcSet={`${fileName}/m/1024x0 1x,${fileName}/m/2048x0 2x`} media='(max-width: 1024px)' />
        <source srcSet={`${fileName}/m/1366x0 1x,${fileName}/m/2732x0 2x`} media='(max-width: 1366px)' />
        <source srcSet={`${fileName}/m/1600x0 1x,${fileName}/m/3200x0 2x`} media='(max-width: 1600px)' />
        <source srcSet={`${fileName}/m/2800x0 1x,${fileName}/m/4000x0 2x`} media='(max-width: 1920px)' />
        <source srcSet={`${fileName}/m/`} media='(min-width: 1921px)' />
        <img
          className='w-full h-full object-contain'
          alt={alt ?? 'Aliens Exist Gallery Image'}
          src={`${fileName}/m/1600x0`} />
      </picture>
    </div>
  );
};

export default GalleryImage;