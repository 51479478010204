import React from 'react';
import ReactPlayer from 'react-player';
import { useOrientation } from '../../hooks/useOrientation';
import { IconButton } from '@mui/material';
import cross from '../../assets/cross.svg';
import videoLandscape from '../../assets/videos/ae_intro_16-9.mp4';
import videoPortrait from '../../assets/videos/ae_intro_9-16.mp4';

interface VideoIntroProps {
    onComplete: () => void;
}

export function VideoIntro({ onComplete }: VideoIntroProps) {
    const isPortrait = useOrientation();

    const videoUrl = isPortrait
        ? videoPortrait
        : videoLandscape;

    return (
        <div className="fixed inset-0 bg-black z-[999999] flex items-center justify-center">
            <div className='absolute top-4 right-4 text-flieder z-10'>
                <IconButton onClick={onComplete} aria-label='Skip intro'>
                    <img
                        src={cross}
                        alt='cross'
                        className='h-[22px] w-[22px] tablet:h-[36px] tablet:w-[36px]'
                    />
                </IconButton>
            </div>
            <ReactPlayer
                url={videoUrl}
                playing={true}
                controls={false}
                loop={false}
                muted={true}
                playsinline={true}
                width='100%'
                height='100%'
                className={`${isPortrait ? 'portrait' : 'landscape'}`}
                onEnded={onComplete}
                config={{
                    file: {
                        attributes: {
                            style: {
                            }
                        }
                    }
                }}
            />
        </div >
    );
}