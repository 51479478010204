import Divider from '@mui/material/Divider';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import React, { useState } from 'react';
import HeaderImageAliensExistTwoColumns from '../../assets/ae_logo_two_columns.png';
import HeaderImageAliensExist from '../../assets/wortmarke_aliens_test_png_02.png';
import { StoryblokAsset } from '../../types/StoryblokAsset';
import RotatingCube from '../base/RotatingCube';

import cubeBack from '../../assets/images/cube/back.svg';
import cubeBottom from '../../assets/images/cube/bottom.svg';
import cubeFront from '../../assets/images/cube/front.svg';
import cubeLeft from '../../assets/images/cube/left.svg';
import cubeRight from '../../assets/images/cube/right.svg';
import cubeTop from '../../assets/images/cube/top.svg';

type StageProps = {
  blok: {
    headerImage?: StoryblokAsset;
    columns: any[];
  };
};

const Stage = ({ blok }: StageProps) => {
  const columnsCount = blok.columns.length;
  let [darkmode, setDarkmode] = useState(false);


  const getGridClass = (columns: number) => {
    switch (columns) {
      case 1:
        return 'tablet:grid-cols-1';
      case 2:
        return 'tablet:grid-cols-2';
      case 3:
        return 'tablet:grid-cols-3';
      case 4:
        return 'tablet:grid-cols-4';
      case 5:
        return 'tablet:grid-cols-5';
      default:
        return 'tablet:grid-cols-1';
    }
  };

  const gridClass = `grid grid-cols-1 ${getGridClass(
    columnsCount
  )} gap-6 place-items-center items-start mx-auto max-w-container container pt-[55px] pb-[40px] z-10`;

  const cubeStyle = darkmode ? 'absolute overflow-hidden z-[-1] top-0 left-0 right-0 bottom-0 pointer-events-none opacity-10' : 'absolute overflow-hidden z-[-1] top-0 left-0 right-0 bottom-0 pointer-events-none opacity-30'

  return (
    <div className='relative' style={{backgroundColor: darkmode ? '#000': 'transparent'}}>
      <div
        {...storyblokEditable(blok)}
        className='pt-15 tablet:pb-15'
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          color: darkmode ? '#fff' : '#000',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <div className={cubeStyle} >
          <RotatingCube
            speed={20}
            dimension={1800}
            front={cubeFront}
            back={cubeBack}
            left={cubeLeft}
            right={cubeRight}
            top={cubeTop}
            bottom={cubeBottom}
          />
        </div>
        <div className={`${gridClass} hidden tablet:grid`}>
          {blok.columns.map((column) => (
            <Divider
              key={column._uid}
              // className='w-full text-newspaper-decorative'
              className={darkmode ? 'w-full bg-white' : 'w-full text-newspaper-decorative'}
            />
          ))}
        </div>
        <div className={darkmode ? 'relative z-[-2] colorize-lilac w-full pt-[44px]' : 'relative z-[-2] w-full pt-[44px]'}>
          <picture
            onClick={() => setDarkmode(!darkmode)}
            className='cursor-pointer'
            style={{ width: '100vw', boxSizing: 'content-box' }}
          >
            <source media='(max-width: 767px)' srcSet={HeaderImageAliensExistTwoColumns} />
            <source media='(min-width: 767px)' srcSet={HeaderImageAliensExist} />
            <img
              src={HeaderImageAliensExist}
              alt='Aliens Exist'
              style={{ maxWidth: '104.5%', marginLeft: '-2.25%' }}
            />
          </picture>
        </div>

        <div className={gridClass}>
          {blok.columns.map((column) => {
            return <StoryblokComponent blok={column} darkmode={darkmode} key={column._uid} />
          }
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Stage);
