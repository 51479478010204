import { storyblokEditable } from '@storyblok/react';
import React, { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../Button';
import PageTitle from '../base/PageTitle';
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from 'swiper';
import { Autoplay } from "swiper/modules";
import type { AutoplayOptions } from 'swiper/types';

import "swiper/css";

type ProjectCardProps = {
  title: string;
  description: string;
  slug: string;
  slider?: {
    component: string;
    image: {
      alt?: string;
      filename?: string;
    };
    _editable: string;
    _uid: string;
  }[];
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  slug,
  slider,
}) => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const swiperRef = useRef<SwiperType>();
  const onInit = (Swiper: SwiperType): void => {
    swiperRef.current = Swiper;
    swiperRef.current?.autoplay.stop();
  };

  // slider initially starts with a high speed (autoplay.delay = 1) so that there is quick change between slide 0 and 1
  // then slider is set to a lower speed (autoplay.delay = 750) for the further slides
  const settings = {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: false,
    speed: 1,
    autoplay: {
      delay: 1,
      stop: true
    } as AutoplayOptions,
  };

  const handleMouseEnter = () => {
    if (swiperRef.current?.autoplay) {
      swiperRef.current.autoplay.start();
      swiperRef.current.params.autoplay = {
        ...(swiperRef.current.params.autoplay as AutoplayOptions),
        delay: 750,
      };
    };
  }
  const handleMouseLeave = () => {
    if (swiperRef.current?.autoplay) {
      swiperRef.current.autoplay.stop();
      swiperRef.current.params.autoplay = {
        ...(swiperRef.current.params.autoplay as AutoplayOptions),
        delay: 0,
      };
    }
    swiperRef.current?.slideTo(0, 0, false); // change directly to first slide when cursor leaves the slider
  };

  return (
    <Link
      to={`${locale === 'de' ? '' : '/' + locale}/projects/${slug}`}
      className='block my-4'
      aria-label={`Project: ${title}`}
    >
      <div className='relative w-full h-auto overflow-hidden' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Swiper
          onInit={onInit}
          modules={[Autoplay]}
          className="mySwiper"
          {...settings}
        >
          {slider?.map((item) => (
            <SwiperSlide key={item._uid}>
              <div className="relative w-full h-auto">
                <img
                  src={item.image.filename}
                  alt={title}
                  className="w-full h-auto"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='py-4'>
        <div className='style-13-mobile tablet:style-13 mb-2'>{title}</div>
        <p className='style-14-mobile tablet:style-14'>{description}</p>
      </div>
    </Link>
  );
};

type LookBookProps = {
  blok: {
    _uid: string;
    _editable: string;
    headline: string;
    subtitle: string;
    project_card: ProjectCardProps[];
  };
};

const LookBook: React.FC<LookBookProps> = ({ blok }) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = useCallback(() => {
    setShowAll(true);
  }, []);

  const displayedProject = showAll
    ? blok.project_card
    : blok.project_card.slice(0, 6);

  const { t } = useTranslation();

  return (
    <div {...storyblokEditable(blok)} className='py-8'>
      <div className='container mx-auto max-w-container text-center'>
        <PageTitle headline={blok.headline} subtitle={blok.subtitle} />
        <div className='grid grid-cols-1 tablet:grid-cols-2 gap-8 justify-center'>
          {displayedProject.map((project, index) => (
            <ProjectCard
              key={project.slug}
              title={project.title}
              description={project.description}
              slug={project.slug}
              slider={project.slider}
            />
          ))}
        </div>

        {!showAll && (
          <Button
            onClick={handleShowAll}
            label={t('show_all_projects')}
            className='mt-9'
          />
        )}
      </div>
    </div>
  );
};

export default LookBook;
