import React from 'react';
import ReactPlayer from 'react-player';

type GalleryVideoProps = {
  fileName?: string;
  isTwoColumn?: boolean;
};

const GalleryVideo: React.FC<GalleryVideoProps> = ({ fileName, isTwoColumn }) => {
  return (
    <div className={isTwoColumn ? 'w-1/2 float-left h-auto overflow-hidden' : 'w-full h-auto overflow-hidden'}>
      <ReactPlayer
        url={fileName}
        playing={true}
        controls={false}
        loop={true}
        muted={true}
        playsinline={true}
        width='100%'
        height='100%'
      />
    </div>
  );
};

export default GalleryVideo;