type VinylContactProps = {
    topline?: string;
    headline?: string;
    subline?: string;
    phoneNumber?: string;
    emailAddress?: string;
    type?: 'email' | 'phone';
};

export const VinylContact = ({ topline, headline, subline, phoneNumber, emailAddress, type }: VinylContactProps) => {

    const getHref = () => {
        if (type === 'phone') {
            return `tel:${phoneNumber}`
        } else if (type === 'email') {
            return `mailto:${emailAddress}`
        } else {
            return '#';
        }
    }

    return (
        <section
            className='
            relative flex flex-col justify-center items-center bg-black/85 rounded-full 
            w-[520px] h-[520px] 
            tablet:w-[660px] tablet:h-[660px] 
            desktop:w-[1016px] desktop:h-[1016px] 
            min-w-[520px] min-h-[520px]
            tablet:min-w-[660px] tablet:min-h-[660px]
            desktop:min-w-[1016px] desktop:min-h-[1016px]
        '>
            <a href={getHref()} className='absolute w-[274px] h-[274px] tablet:w-[320px] tablet:h-[320px] desktop:w-[536px] desktop:h-[536px] z-[100] flex flex-col justify-center items-center bg-transparent rounded-full border border-white group'>
                <p className='vinyl-contact-text-mobile desktop:style-8 text-white group-hover:text-flieder'>
                    {topline}
                </p>
                <h1 className='vinyl-contact-hl-mobile desktop:style-10 text-flieder mt-1 mb-2 group-hover:text-white uppercase'>
                    {headline}
                </h1>
                <p className='vinyl-contact-text-mobile desktop:style-8 text-white desktop:underline group-hover:text-flieder'>
                    {subline}
                </p>
            </a>
            <div className="animate-[blendVinylCircle1_3s_linear_infinite] absolute w-[304px] h-[304px] tablet:w-[350px] tablet:h-[350px] desktop:w-[566px] desktop:h-[566px] z-[99] rounded-full border border-white bg-transparent"></div>
            <div className="animate-[blendVinylCircle2_3s_linear_infinite] absolute w-[334px] h-[334px] tablet:w-[380px] tablet:h-[380px] desktop:w-[596px] desktop:h-[596px] z-[95] rounded-full border border-white bg-transparent"></div>
            <div className="animate-[blendVinylCircle3_3s_linear_infinite] absolute w-[364px] h-[364px] tablet:w-[410px] tablet:h-[410px] desktop:w-[626px] desktop:h-[626px] z-[93] rounded-full border border-white/90 bg-transparent"></div>
            <div className="animate-[blendVinylCircle4_3s_linear_infinite] absolute w-[394px] h-[394px] tablet:w-[440px] tablet:h-[440px] desktop:w-[656px] desktop:h-[656px] z-[91] rounded-full border border-white/85 bg-transparent"></div>
            <div className="animate-[blendVinylCircle5_3s_linear_infinite] absolute w-[424px] h-[424px] tablet:w-[470px] tablet:h-[470px] desktop:w-[686px] desktop:h-[686px] z-[89] rounded-full border border-white/80 bg-transparent"></div>
            <div className="animate-[blendVinylCircle6_3s_linear_infinite] absolute w-[454px] h-[454px] tablet:w-[500px] tablet:h-[500px] desktop:w-[716px] desktop:h-[716px] z-[87] rounded-full border border-white/75 bg-transparent"></div>
            <div className="animate-[blendVinylCircle7_3s_linear_infinite] absolute w-[484px] h-[484px] tablet:w-[530px] tablet:h-[530px] desktop:w-[746px] desktop:h-[746px] z-[85] rounded-full border border-white/70 bg-transparent"></div>
            <div className="animate-[blendVinylCircle8_3s_linear_infinite] absolute hidden tablet:block w-[514px] h-[514px] tablet:w-[560px] tablet:h-[560px] desktop:w-[776px] desktop:h-[776px] z-[83] rounded-full border border-white/65 bg-transparent"></div>
            <div className="animate-[blendVinylCircle9_3s_linear_infinite] absolute hidden tablet:block w-[5444px] h-[544px] tablet:w-[590px] tablet:h-[590px] desktop:w-[806px] desktop:h-[806px] z-[81] rounded-full border border-white/55 bg-transparent"></div>
            <div className="animate-[blendVinylCircle10_3s_linear_infinite] absolute hidden tablet:block w-[574px] h-[574px] tablet:w-[620px] tablet:h-[620px] desktop:w-[836px] desktop:h-[836px] z-[79] rounded-full border border-white/40 bg-transparent"></div>
            <div className="animate-[blendVinylCircle11_3s_linear_infinite] absolute hidden tablet:block w-[604px] h-[604px] tablet:w-[650px] tablet:h-[650px] desktop:w-[866px] desktop:h-[866px] z-[77] rounded-full border border-white/30 bg-transparent"></div>
            <div className="animate-[blendVinylCircle12_3s_linear_infinite] absolute hidden desktop:block tablet:w-[680px] tablet:h-[680px] desktop:w-[896px] desktop:h-[896px] z-[75] rounded-full border border-white/15 bg-transparent"></div>
        </section >
    );
};


