import React, { useEffect, useState, useMemo } from 'react';
import { useStoryblok, StoryblokComponent } from '@storyblok/react';
import useStoryblokConfig from './hooks/useStoryblokConfig';
import Layout from './components/Layout';
import { useLocation } from 'react-router-dom';
import BackToTop from './components/base/BackToTop';
import SkeletonLoader from './components/base/SkeletonLoader';
import NotFound from './components/NotFound';
import { locales, defaultLocale } from './translations/i18nConfig';
import './translations/i18n';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { VideoIntro } from './components/base/VideoIntro';
import { getCookie, setCookie } from './hooks/useCookie';

interface ErrorState {
  statusCode: number;
  statusMessage: string;
  fatal: boolean;
}

// Define the Storyblok version type
type StoryblokVersion = 'draft' | 'published';

function App() {
  const location = useLocation();
  const [error, setError] = useState<ErrorState | null>(null);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const [showIntro, setShowIntro] = useState<boolean>(false);

  useEffect(() => {
    const hasSeenIntro = getCookie('hasSeenIntro');
    if (!hasSeenIntro) {
      setShowIntro(true);
    }
  }, []);

  // Extract the locale and slug from the URL
  const { locale, slug } = useMemo(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const locale = locales.includes(pathSegments[0])
      ? pathSegments[0]
      : defaultLocale;
    const slug = pathSegments.includes(locale)
      ? pathSegments.slice(1).join('/') || 'home'
      : pathSegments.join('/') || 'home';
    return { locale, slug };
  }, [location.pathname]);

  useEffect(() => {
    if (locale !== i18n.language) {
      i18n.changeLanguage(locale);
    }
  }, [locale, i18n]);

  // Get the Storyblok version from the environment variable
  const version =
    (process.env.REACT_APP_STORYBLOK_VERSION as StoryblokVersion) || 'draft';

  // Pass the language to useStoryblok to retrieve content in the correct language
  const story = useStoryblok(slug, {
    version,
    language: locale === 'de' ? '' : locale,
  });
  const config = useStoryblokConfig();

  useEffect(() => {
    setLoading(true);
    setError(null);

    if (story && story.content) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        if (!story || !story.content) {
          setError({
            statusCode: 404,
            statusMessage: 'Page Not Found',
            fatal: true,
          });
          setLoading(false);
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
    window.scrollTo(0, 0);
  }, [story, location]);

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return (
      <Layout story={story} config={config}>
        <NotFound />
      </Layout>
    );
  }

  const defaultTitle = 'Aliens Exist | Werbeagentur';
  const defaultDescription =
    'Wir entwickeln Markenauftritte mit enormer Anziehungskraft. Entdecken Sie unsere Werbeagentur und Projekte.';

  const handleIntroComplete = () => {
    setCookie('hasSeenIntro', 'true', 14); // 14 days
    setShowIntro(false);
  };

  return (
    <>
      <Helmet>
        <title>
          {story.content.meta?.title ? story.content.meta.title : defaultTitle}
        </title>
        <meta
          name='description'
          content={
            story.content.meta?.description
              ? story.content.meta.description
              : defaultDescription
          }
        />
      </Helmet>
      {showIntro && <VideoIntro onComplete={handleIntroComplete} />}
      <Layout story={story} config={config}>
        <BackToTop />
        <StoryblokComponent blok={story.content} />
      </Layout>
    </>
  );
}

export default App;
