import React from 'react';
import {
  Link as MUILink,
  LinkProps as MUILinkProps,
  SvgIconProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { StoryblokLink } from '../../types/StoryblokLink';

import Arrow from '../../assets/arrow.svg';
import ArrowDark from '../../assets/arrow_dark.svg';

export interface CustomLinkProps extends MUILinkProps {
  label?: string;
  icon?: React.ReactElement<SvgIconProps>;
  color?: string;
  hoverColor?: string;
  textColor?: string;
  storyblokLink?: StoryblokLink;
  darkmode?: boolean;
}

const CustomLink = styled(MUILink, {
  shouldForwardProp: (prop) =>
    prop !== 'customcolor' &&
    prop !== 'hovercolor' &&
    prop !== 'textcolor' &&
    prop !== 'hasLabel',
})<{
  customcolor?: string;
  hovercolor?: string;
  textcolor?: string;
  hasLabel: boolean;
}>(({ theme, customcolor, hovercolor, textcolor, hasLabel }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  textDecoration: 'none',
  cursor: 'pointer',
  padding: '5px 0',
  color: customcolor || 'black',
  transition: 'color 0.5s ease',
  '&:hover': {
    color:
      hovercolor ??
      customcolor ??
      (theme.palette.primary ? theme.palette.primary.main : 'black'),
    '& .label': {
      color: hovercolor ?? textcolor ?? 'inherit',
    },
    '& .svg-icon': {
      transform: hasLabel ? 'scale(1.2)' : 'none',
    },
  },
  '& .svg-icon': {
    transition: 'transform 0.5s ease',
  },
  '& .label': {
    color: textcolor || 'inherit',
  },
}));

const Link: React.FC<CustomLinkProps> = ({
  label,
  icon,
  color,
  hoverColor,
  textColor,
  storyblokLink,
  darkmode = false,
  ...props
}) => {
  const location = useLocation(); // Use the useLocation hook to get the current locale

  // Extract the locale from the URL
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const locale = pathSegments[0] || '';

  const getLinkUrl = (link: StoryblokLink) => {
    if (!link) return '#';

    const url = link.cached_url.startsWith('/')
      ? link.cached_url
      : `/${link.cached_url}`;

    // Not adding the locale if it's already present
    if (url.startsWith(`/${locale}`)) {
      return url; // If the URL already starts with the locale, return the URL as is
    }

    switch (link.linktype) {
      case 'story':
        // Add the locale to the URL
        return `/${locale}${url}`;
      case 'url':
        return link.url;
      default:
        return '#';
    }
  };

  const href = storyblokLink
    ? getLinkUrl(storyblokLink)
    : props.href ?? undefined;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (href?.includes('#')) {
      e.preventDefault();

      const anchor = href.split('#')[1];
      const targetElement = document.getElementById(anchor);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.warn(`Element with ID "${anchor}" not found.`);
      }
    }
  };

  const hasLabel = !!label;

  return href ? (
    <CustomLink
      {...props}
      customcolor={color}
      hovercolor={hoverColor}
      textcolor={textColor}
      href={href}
      onClick={handleClick}
      hasLabel={hasLabel}
    >
      {icon || (
        <img
          src={ darkmode ? ArrowDark : Arrow }
          alt='arrow'
          className='svg-icon'
          style={{ width: '11px', height: '10px' }}
        />
      )}
      {label && (
        <span
          className='label style-5'
          style={{
            marginLeft: icon ? '8px' : '5px',
          }}
        >
          {label}
        </span>
      )}
    </CustomLink>
  ) : (
    <CustomLink
      {...props}
      customcolor={color}
      hovercolor={hoverColor}
      textcolor={textColor}
      hasLabel={hasLabel}
    >
      {icon || (
        <img
          src={ darkmode ? ArrowDark : Arrow }
          alt='arrow'
          className='svg-icon'
          style={{ width: '11px', height: '10px' }}
        />
      )}
      {label && (
        <span
          className='label style-5'
          style={{ marginLeft: icon ? '8px' : '5px' }}
        >
          {label}
        </span>
      )}
    </CustomLink>
  );
};

export default Link;
