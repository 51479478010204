import React from "react";

type RotatingCubeProps = {
    speed?: number;
    dimension?: number;
    front?: string;
    back?: string;
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
};

const RotatingCube = ({
    speed = 5,
    dimension = 500,
    front='',
    back='',
    left='',
    right='',
    top='',
    bottom=''
}: RotatingCubeProps) => {

    let halfDimension: number = dimension / 2;

    let styleCube: React.CSSProperties = {
        width: `${dimension}px`,
        height: `${dimension}px`,
        transformStyle: 'preserve-3d',
        animation: `rotateCube ${speed}s infinite linear`,
    };

    let styleFace: React.CSSProperties = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
    };

    let styleFaceFront: React.CSSProperties = {
        ...styleFace,
        transform: 'translateZ('+ halfDimension +'px)',
        backgroundImage: `url(${front})`,
    };

    let styleFaceBack: React.CSSProperties = {
        ...styleFace,
        transform: 'rotateY(180deg) translateZ('+ halfDimension +'px)',
        backgroundImage: `url(${back})`,
    };

    let styleFaceLeft: React.CSSProperties = {
        ...styleFace,
        transform: 'rotateY(-90deg) translateZ('+ halfDimension +'px)',
        backgroundImage: `url(${left})`,
    };

    let styleFaceRight: React.CSSProperties = {
        ...styleFace,
        transform: 'rotateY(90deg) translateZ('+ halfDimension +'px)',
        backgroundImage: `url(${right})`,
    };

    let styleFaceTop: React.CSSProperties = {
        ...styleFace,
        transform: 'rotateX(90deg) translateZ('+ halfDimension +'px)',
        backgroundImage: `url(${top})`,
    };

    let styleFaceBottom: React.CSSProperties = {
        ...styleFace,
        transform: 'rotateX(-90deg) translateZ('+ halfDimension +'px)',
        backgroundImage: `url(${bottom})`,
    };

    return (
        <div className="cube" style={styleCube}>
            <div className="face front" style={styleFaceFront}></div>
            <div className="face back" style={styleFaceBack}></div>
            <div className="face right" style={styleFaceRight}></div>
            <div className="face left" style={styleFaceLeft}></div>
            <div className="face top" style={styleFaceTop}></div>
            <div className="face bottom" style={styleFaceBottom}></div>
        </div>
    );
}

export default RotatingCube;