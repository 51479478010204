import React, { useRef } from 'react';
import { storyblokEditable } from '@storyblok/react';
import ShowcaseItem from '../ShowcaseItem';
import { StoryblokLink } from '@/types/StoryblokLink';
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from 'swiper';
import { useSliderControls } from '../../hooks/useSliderControls';
import { FreeMode, Autoplay } from "swiper/modules";
import 'swiper/css';
import "swiper/css/free-mode";

type ImageType = {
  _editable: string;
  _uid: string;
  component: string;
  image: {
    alt: string;
    filename: string;
  };
  link?: StoryblokLink;
};

type ShowCaseProps = {
  blok: {
    headline: string;
    images: ImageType[];
  };
};

const Showcase: React.FC<ShowCaseProps> = ({ blok }) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const {
    speed,
    cursorStyle,
    handleMouseMove,
    handleMouseLeave,
  } = useSliderControls(swiperRef);

  const settings = {
    loop: true,
    speed: speed,
    slidesPerView: 3.5,
    centeredSlides: true,
    className: 'ShowcaseSlider',
    freeMode: {
      enabled: true,
      momentum: true,
      momentumRatio: 0.8,
      momentumVelocityRatio: 0.8,
      sticky: false,
    },
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.5
      },
      768: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 3.5
      },
    },
  };



  return (
    <div
      className='pt-8 pb-20 bg-white relative'
      {...storyblokEditable(blok)}
    >
      <div className='max-w-container container mx-auto'>
        <h2 className='style-3-mobile tablet:style-3 text-left mb-8 lowercase'>
          {blok.headline ?? 'Showcase'}
        </h2>
      </div>
      <div className='relative w-full group' onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave} style={{ cursor: cursorStyle }}>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Autoplay, FreeMode]}
          {...settings}
        >
          {blok.images.map((item) => (
            <SwiperSlide key={item._uid}>
              <div
                className='px-2 desktop:px-4 relative bg-white'
                style={{ padding: '0 15px', width: '100%', height: 'auto' }}
              >
                <ShowcaseItem
                  imageSrc={item.image.filename}
                  title={item.image.alt}
                  link={item.link}
                  cursorStyle={cursorStyle}
                />
              </div>
            </SwiperSlide>
          ))}

        </Swiper>
      </div>
    </div>
  );
};

export default Showcase;
