import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import 'swiper/css';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import JobCard from '../JobCard';
import { storyblokEditable } from '@storyblok/react';
import { StoryblokLink } from '../../types/StoryblokLink';

type JobListProps = {
  blok: {
    headline: string;
    jobs: {
      title: string;
      subtitle: string;
      description: string;
      link_text: string;
      link: StoryblokLink;
    }[];
  };
};

const JobList: React.FC<JobListProps> = ({ blok }) => {
  const swiperRef = useRef<SwiperType>();

  const settings = {
    loop: true,
    speed: 500,
    slidesPerView: Math.min(3, blok.jobs.length), // Show a maximum of 3 slides or less if fewer jobs
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      760: {
        slidesPerView: Math.min(2, blok.jobs.length)
      },
      1024: {
        slidesPerView: Math.min(3, blok.jobs.length)
      }
    },
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  // Double the slides array to ensure enough slides for loop mode; due to a bug in Swiper with low number of slides
  const duplicatedSlides = [...blok.jobs, ...blok.jobs];

  return (
    <div
      {...storyblokEditable(blok)}
      className='container mx-auto max-w-container pt-6 pb-48 text-center'
    >
      <h2 className='style-19-mobile phone:style-19 uppercase'>
        {blok.headline}
      </h2>
      {duplicatedSlides.length > 1 ? (
        <>
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            loopAddBlankSlides={false}
            className="mySwiper mt-8"
            {...settings}
          >
            {duplicatedSlides.map((job, index) => (
              <SwiperSlide key={index}>
                <div className='px-2'>
                  <JobCard
                    title={job.title}
                    subtitle={job.subtitle}
                    description={job.description}
                    link_text={job.link_text}
                    link={job.link}
                  />
                </div>
              </SwiperSlide>
            ))}
            <div className='flex justify-center mt-8 gap-x-16'>
              <ArrowBackIcon onClick={handlePrev} className='cursor-pointer !text-[2.5rem]' />
              <ArrowForwardIcon onClick={handleNext} className='cursor-pointer !text-[2.5rem]' />
            </div>
          </Swiper>
        </>
      ) : (
        blok.jobs.map((job, index) => (
          <div key={index} className='px-2 mt-8'>
            <JobCard
              title={job.title}
              subtitle={job.subtitle}
              description={job.description}
              link_text={job.link_text}
              link={job.link}
            />
          </div>
        ))
      )
      }
    </div >
  );
};

export default JobList;